
type AuthRequest = {
  username: string;
  password: string;
}

export type AuthResponse = {
  success: boolean;
  message: string;
  redirect_path: string;
}

export function Auth(username: string, password: string, success: Function, error: Function) {
  let ar: AuthRequest = {
    username: username,
    password: password,
  }
  fetch('/api/v2/auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(ar),
  }).then((response) => {
    response.json().then((data2) => {

      let data = data2 as AuthResponse;

      if (data.success) {
        success(data);
      } else {
        error(data);
      }

    });
  });
}

export function Logout() {
  fetch('/api/v2/auth', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  }).then((response) => {
    response.json().then((data) => {
      if (data.success) {
        document.location = '/login/';
      }
    });
  });
}

// signup

type SignupRequest = {
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
  sms_number: string;
}

type SignupResponse = {
  success: boolean;
  message: string;
  redirect_path: string;
}

export function Signup(username: string, password: string, email: string,
  first_name: string, last_name: string, sms_number: string, success: Function, error: Function) {

  let sr: SignupRequest = {
    username: username,
    password: password,
    email: email,
    first_name: first_name,
    last_name: last_name,
    sms_number: sms_number,
  }
  fetch('/api/v2/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(sr),
  }).then((response) => {
    response.json().then((data2) => {

      let data = data2 as SignupResponse;

      if (data.success) {
        success(data);
      } else {
        error(data);
      }

    });
  });
}

