// *******************English*************************************

//components
import footer_en from "./en/component/footer.json";
import betslip_en from "./en/component/betslip.json";
import header_en from "./en/component/header.json";
import sport_selected_en from "./en/component/sport-selected.json";
import side_selected_en from "./en/component/side-selected.json";

//pages
import balance_en from "./en/pages/balance.json"
import betting_en from "./en/pages/betting.json"
import settings_en from "./en/pages/settings.json";
import scores_en from "./en/pages/scores.json";
import pending_en from "./en/pages/pending.json";
import figures_en from "./en/pages/figures.json";
import messages_en from "./en/pages/messages.json";
import login_en from "./en/pages/login.json";
import transactions_en from "./en/pages/transactions.json";
import rules_en from "./en/pages/rules.json";
import transferfunds_en from "./en/pages/transferfunds.json";
import help_en from "./en/pages/help.json";

//data
import sports_en from "./en/data/sports.json";
import periods_en from "./en/data/periods.json";



// *******************Spanish*************************************

//components
import footer_es from "./es/component/footer.json";
import betslip_es from "./es/component/betslip.json";
import header_es from "./es/component/header.json";
import sport_selected_es from "./es/component/sport-selected.json";
import side_selected_es from "./es/component/side-selected.json";

//pages
import balance_es from "./es/pages/balance.json"
import betting_es from "./es/pages/betting.json"
import settings_es from "./es/pages/settings.json";
import scores_es from "./es/pages/scores.json";
import pending_es from "./es/pages/pending.json";
import figures_es from "./es/pages/figures.json";
import messages_es from "./es/pages/messages.json";
import login_es from "./es/pages/login.json";
import transactions_es from "./es/pages/transactions.json";
import rules_es from "./es/pages/rules.json";
import help_es from "./es/pages/help.json";



//data
import sports_es from "./es/data/sports.json";
import periods_es from "./es/data/periods.json";


// *******************Korean*************************************

//components
import footer_ko from "./ko/component/footer.json";
import betslip_ko from "./ko/component/betslip.json";
import header_ko from "./ko/component/header.json";
import sport_selected_ko from "./ko/component/sport-selected.json";
import side_selected_ko from "./ko/component/side-selected.json";

//pages
import balance_ko from "./ko/pages/balance.json"
import betting_ko from "./ko/pages/betting.json"
import settings_ko from "./ko/pages/settings.json";
import scores_ko from "./ko/pages/scores.json";
import pending_ko from "./ko/pages/pending.json";
import figures_ko from "./ko/pages/figures.json";
import messages_ko from "./ko/pages/messages.json";
import login_ko from "./ko/pages/login.json";
import transactions_ko from "./ko/pages/transactions.json";
import rules_ko from "./ko/pages/rules.json";
import help_ko from "./ko/pages/help.json";


//data
import sports_ko from "./ko/data/sports.json";
import periods_ko from "./ko/data/periods.json";


const resources = {
  en: {
    //components
    footer: footer_en,
    betslip: betslip_en,
    header: header_en,
    sport_selected: sport_selected_en,
    side_selected: side_selected_en,

    //pages
    balance: balance_en,
    betting: betting_en,
    settings: settings_en,
    scores: scores_en,
    pending: pending_en,
    figures: figures_en,
    transactions: transactions_en,
    login: login_en,
    messages: messages_en,
    rules: rules_en,
    transferfunds: transferfunds_en,
    help: help_en,
    //data
    sports: sports_en,
    periods: periods_en

  },
  es: {
    //components
    footer: footer_es,
    betslip: betslip_es,
    header: header_es,
    sport_selected: sport_selected_es,
    side_selected: side_selected_es,
    //pages
    balance: balance_es,
    betting: betting_es,
    settings: settings_es,
    scores: scores_es,
    pending: pending_es,
    figures: figures_es,
    transactions: transactions_es,
    login: login_es,
    messages: messages_es,
    rules: rules_es,
    help: help_es,
    //data
    sports: sports_es,
    periods: periods_es

  },
  ko: {
    //components
    footer: footer_ko,
    betslip: betslip_ko,
    header: header_ko,
    sport_selected: sport_selected_ko,
    side_selected: side_selected_ko,
    //pages
    balance: balance_ko,
    betting: betting_ko,
    settings: settings_ko,
    scores: scores_ko,
    pending: pending_ko,
    figures: figures_ko,
    transactions: transactions_ko,
    login: login_ko,
    messages: messages_ko,
    rules: rules_ko,
    help: help_ko,
    //data
    sports: sports_ko,
    periods: periods_ko
  }
};

export default resources;


