import { createI18n } from "vue-i18n"

import resources from "./translations/resources";

export default createI18n({
    locale: import.meta.env.VITE_DEFAULT_LOCALE,
    fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
    legacy: false,
    globalInjection: true,
    missingWarn : false,
    warnHtmlInMessage: false,
    messages : resources
  })